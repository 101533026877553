import { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Grid, Text, Box } from "theme-ui";

import { DestinationsCatalog } from "src/components/destinations/destinations-catalog";
import { PostSetupForm } from "src/components/destinations/post-setup-form";
import { SetupForm } from "src/components/destinations/setup-form";
import {
  TestDestinationBadge,
  TestNewDestinationButton,
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import { Header, SidebarForm } from "src/components/page";
import { Slug } from "src/components/slug";
import { useUser } from "src/contexts/user-context";
import {
  useIsResourceSlugAvailableQuery,
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateDestinationV2Mutation,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Label } from "src/ui/label";
import { Link } from "src/ui/link";
import { useDestination, useDestinations } from "src/utils/destinations";
import { generateSlug } from "src/utils/slug";
import { useQueryString } from "src/utils/use-query-string";

import { colors } from "../../../../design";

type CreateDestinationWizardArgs = {
  destinationDefinition?: DestinationDefinition;
  step: number;
  onConnectClick?(defintion: DestinationDefinition): void;
  onSubmit({ id, definition }: { id?: string; definition: DestinationDefinition }): void;
  setStep(step: number): void;
  catalogSidebarTop: number;
};

type Config = Record<string, unknown>;

export const useCreateDestinationWizard = ({
  destinationDefinition: externalDestinationDefinition,
  onConnectClick,
  onSubmit,
  setStep,
  catalogSidebarTop,
}: CreateDestinationWizardArgs) => {
  const { user } = useUser();
  const { addToast } = useToasts();
  const {
    data: { id: _destinationId, onboardingDestinationId },
    loading: loadingParams,
  } = useQueryString();

  const destinationId = onboardingDestinationId || _destinationId;

  const [name, _setName] = useState<string>();
  const [slug, _setSlug] = useState<string>("");
  const [dirtySlug, setDirtySlug] = useState<boolean>(false);
  const [config, setConfig] = useState<Config | undefined>({});
  const [oAuthSuccess, setOAuthSuccess] = useState<boolean>(false);
  const [credentialId, setCredentialId] = useState<string | undefined>();

  const { mutateAsync: createDestination, isLoading: creating } = useCreateDestinationV2Mutation();
  const { mutateAsync: updateDestination, isLoading: updating } = useUpdateDestinationV2Mutation();

  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);

  // Retrieve the full catalog of destinations for Step 1, not needed
  // if the destination already exists
  const {
    data: { definitions },
    loading: loadingCatalog,
    error: catalogError,
  } = useDestinations();

  // Retrieve the destination if already exists (Steps 2 and 3)
  const {
    data: { destination, definition: destinationDefinition },
    loading: loadingDestination,
  } = useDestination(destinationId ?? "", {
    pause: !destinationId,
  });

  // Definition selected when creating the destination (Step 1)
  const [definition, setDefinition] = useState<DestinationDefinition | undefined>();

  // Or if the destination is already created
  useEffect(() => {
    if (destination && destinationDefinition) {
      setDefinition(destinationDefinition);
      setOAuthSuccess(true);
      setStep(1);
    }
  }, [destination, destinationDefinition]);

  // Onboarding: set definition if it changes externally
  useEffect(() => {
    if (externalDestinationDefinition) {
      setDefinition(externalDestinationDefinition);
    }
  }, [externalDestinationDefinition]);

  useEffect(() => {
    if (definition?.name && definition?.name !== externalDestinationDefinition?.name) {
      analytics.track("Destination Type Clicked", {
        destination_type: definition.name,
        coming_soon: false,
      });
    }
  }, [definition]);

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Destination Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult]);

  const { data, isLoading: loadingSlug } = useIsResourceSlugAvailableQuery(
    {
      resourceType: "destinations",
      slug,
    },
    { enabled: Boolean(slug) },
  );

  const available = data?.isResourceSlugAvailable;

  const setSlug = (value) => {
    _setSlug(value);
    setDirtySlug(true);
  };

  const setName = (value) => {
    _setName(value);
    if (!dirtySlug) {
      _setSlug(generateSlug(value));
    }
  };

  const create = async () => {
    let id;
    if (oAuthSuccess && destinationId) {
      const { updateDestinationWithSecrets } = await updateDestination({
        id: destinationId,
        object: {
          config: {
            ...destination?.config,
            ...config,
          },
          name: name,
          slug: slug,
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
        },
      });
      id = updateDestinationWithSecrets?.id;
    } else {
      const object = {
        config,
        name: name,
        slug: slug,
        setup_complete: true,
        created_by: user?.id != null ? String(user?.id) : undefined,
        type: definition?.type,
      };

      if (credentialId) {
        object["credential_id"] = credentialId;
        if (!config) {
          object["config"] = {};
        }
      }

      const { createDestinationWithSecrets } = await createDestination({
        object: object,
      });

      id = createDestinationWithSecrets?.id;
    }

    addToast(`Destination ${name || definition?.name} created!`, {
      appearance: "success",
    });

    analytics.track("Destination Created", {
      destination_id: id,
      destination_name: name,
      destination_type: definition?.name,
    });

    const definitionToSubmit = definition || destinationDefinition;

    if (definitionToSubmit) {
      onSubmit?.({ id, definition: definitionToSubmit });
    }
  };

  const steps = [
    {
      title: "Select",
      disabled: !definition,
      render: () => (
        <DestinationsCatalog
          destinationDefinitions={definitions ?? []}
          error={catalogError}
          selection={definition}
          sidebarTop={catalogSidebarTop}
          onSelect={setDefinition}
        />
      ),
      pageSize: "full",
    },
    {
      pageSize: "medium",
      title: `Connect`,
      disabled: destination?.id ? !oAuthSuccess : !config && !credentialId,
      loading: testing,
      onContinue: () => setStep(2),
      render: () => (
        <>
          {definition && (
            <>
              <Header
                icon={definition.icon}
                rightToolbar={[<TestDestinationBadge key={0} result={testResult} testing={testing} />]}
                title={`Connect ${definition.name}`}
              />
              <Row sx={{ alignItems: "flex-start" }}>
                {oAuthSuccess ? (
                  <Column sx={{ flexGrow: 1 }}>
                    <Text sx={{ fontSize: 2, fontWeight: "semi" }}>Authorization successful</Text>
                    <Text sx={{ fontSize: 1, color: "base.5" }}>
                      Your {definition.name} account was successfully connected.
                    </Text>
                  </Column>
                ) : (
                  <Grid gap={12} mr={8} sx={{ width: "100%", alignItems: "flex-start" }}>
                    {definition.oauthUrl && definition.configurationForm && (
                      <Label size="large">Authorize with one of the following options:</Label>
                    )}

                    {definition.oauthUrl && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontSize: "18px",
                          border: `1px solid ${colors.base[3]}`,
                          padding: 4,
                          borderRadius: 2,
                        }}
                      >
                        <Box>Connect with OAuth</Box>
                        <Link to={`${import.meta.env.VITE_API_BASE_URL}${definition.oauthUrl}/${definition.type}`}>
                          <Button
                            variant="secondary"
                            onClick={() => {
                              if (typeof onConnectClick === "function") {
                                onConnectClick(definition);
                              }
                            }}
                          >
                            Connect
                          </Button>
                        </Link>
                      </Box>
                    )}

                    {definition.oauthUrl && definition.configurationForm && (
                      <Row sx={{ borderBottom: "small", width: "100%" }}></Row>
                    )}

                    <SetupForm
                      config={config}
                      credentialId={credentialId}
                      definition={definition}
                      setConfig={setConfig}
                      setCredentialId={setCredentialId}
                    />
                  </Grid>
                )}
                <SidebarForm
                  hideCompliance
                  buttons={
                    definition?.testConnection &&
                    (!destination?.id ? (
                      <TestNewDestinationButton
                        configuration={config}
                        definition={definition}
                        result={testResult}
                        onError={setTestError}
                        onResult={setTestResult}
                      />
                    ) : (
                      <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <TestUpdatedDestinationButton
                          buttonProps={{ mb: 0, width: "unset" }}
                          destinationId={destination.id}
                          newConfiguration={config}
                          onError={setTestError}
                          onLoading={setTesting}
                          onResult={setTestResult}
                        />
                      </Row>
                    ))
                  }
                  docsUrl={definition.docs ?? ""}
                  name={definition.name}
                />
              </Row>
            </>
          )}
        </>
      ),
    },
    {
      pageSize: "small",
      title: "Finish",
      disabled: !name || !slug || !available,
      loading: creating || updating,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Finalize settings
          </Heading>
          <Row sx={{ alignItems: "flex-start" }}>
            <Grid gap={12} mr={8} sx={{ flexGrow: 1 }}>
              <Field label="Name">
                <Input value={name} onChange={(value) => setName(value)} />
              </Field>
              <Field label="Slug">
                <Slug
                  available={Boolean(available)}
                  loading={loadingSlug}
                  placeholder={"your-destination-slug"}
                  value={slug}
                  onChange={setSlug}
                />
              </Field>
              <PostSetupForm config={config} destination={destination} setConfig={setConfig} />
            </Grid>
          </Row>
        </>
      ),
    },
  ];

  return {
    createDestination: create,
    definition,
    loading: loadingParams || loadingCatalog || loadingDestination,
    oAuthSuccess,
    steps,
  };
};
