import { VFC } from "react";

import { Text, Image } from "theme-ui";

import { DestinationInstancesOrderBy, ResourcePermissionGrant, SyncFragment, SyncsOrderBy } from "src/graphql";
import { ObjectBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { ExternalLinkIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useNavigate } from "src/utils/navigate";
import { getObjectName, SyncStatusBadge } from "src/utils/syncs";
import { formatDatetime } from "src/utils/time";

import { Permission } from "../permission";
import placeholderImage from "./sync-placeholder.png";

type Props = {
  isAudience?: boolean;
  loading?: boolean;
  syncs?: SyncFragment[];
  onAdd: () => void;
  orderBy: SyncsOrderBy | DestinationInstancesOrderBy | undefined;
  onSort: (sortKey: string) => void;
};

export enum SyncSortKeys {
  Status = "status",
  DestinationName = "destination.name",
  LastRunAt = "last_run_at",
  UPDATED_AT = "updated_at",
}

export const Syncs: VFC<Readonly<Props>> = ({ isAudience, loading, orderBy, syncs, onAdd, onSort }) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Status",
      sortDirection: orderBy?.status,
      onClick: () => onSort(SyncSortKeys.Status),
      min: "130px",
      max: "130px",
      cell: ({ status, sync_requests }) => {
        const syncRequest = sync_requests?.[0];
        const request = syncRequest ? syncRequest : { status_computed: status };
        return <SyncStatusBadge request={request} status={status} />;
      },
    },
    {
      name: "Destination",
      sortDirection: orderBy?.destination?.name,
      onClick: () => onSort(SyncSortKeys.DestinationName),
      cell: ({ config, destination: { name, definition } }) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <Image alt={definition?.name} src={definition?.icon} sx={{ width: "18px", flexShrink: 0, mr: 2 }} />

            <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {name || definition?.name}
            </Text>

            {config?.object && (
              <ObjectBadge sx={{ textTransform: "capitalize", ml: 2 }}>{getObjectName(config.object)}</ObjectBadge>
            )}
          </Row>
        );
      },
    },
    {
      name: "Last run",
      sortDirection: orderBy?.last_run_at,
      onClick: () => onSort(SyncSortKeys.LastRunAt),
      max: "max-content",
      cell: ({ id, sync_requests }) => {
        const syncRequest = sync_requests?.[0];

        return (
          <Row sx={{ alignItems: "center" }}>
            {syncRequest && (
              <>
                <Text sx={{ mr: 2, fontWeight: "semi" }}>{formatDatetime(syncRequest?.created_at)}</Text>
                <Link to={`/syncs/${id}/runs/${syncRequest?.id}`}>
                  <Row sx={{ color: "base.4", ":hover": { color: "secondary" } }}>
                    <ExternalLinkIcon size={14} />
                  </Row>
                </Link>
              </>
            )}
          </Row>
        );
      },
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SyncSortKeys.UPDATED_AT),
    },
  ];

  return (
    <Table
      columns={columns}
      data={syncs}
      loading={loading}
      placeholder={{
        custom: (
          <Column sx={{ alignItems: "center" }}>
            <Image src={placeholderImage} sx={{ mb: 6 }} width="187px" />
            <Text sx={{ fontWeight: "bold", fontSize: 3, mb: 1 }}>
              {`This ${isAudience ? "audience" : "model"} is not syncing to any destinations`}
            </Text>
            <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
              <Text sx={{ mb: 4, color: "base.6" }}>Still working on this? Add a sync when you’re ready</Text>
              <Button variant="secondary" onClick={() => onAdd()}>
                Add a sync
              </Button>
            </Permission>
          </Column>
        ),
      }}
      onRowClick={({ id }) => navigate(`/syncs/${id}`)}
    />
  );
};
