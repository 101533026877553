import { useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useAddWorkspaceRoleMutation,
  useGetWorkspaceRolesQuery,
  useUpdateWorkspaceRoleMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Table, TableColumn } from "src/ui/table";

interface Policy {
  version: string;
}

interface Role {
  id: string;
  name: string;
  permissions: Policy;
}

const DEFAULT_POLICY = { version: "2022-04-26", policies: [{ effect: "deny", actions: "*", resource: "*" }] };

const placeholder = {
  title: "No roles",
  error: "Roles failed to load, please try again.",
};

export const Roles = () => {
  const { workspace } = useUser();

  const [editingRole, setEditingRole] = useState<Role | undefined>();
  const { isLoading: creating, mutateAsync: addRole } = useAddWorkspaceRoleMutation();

  const { isLoading, data } = useGetWorkspaceRolesQuery({ workspaceId: workspace?.id });
  const roles = data?.workspaces_by_pk?.roles;

  const roleColumns: TableColumn[] = [
    {
      name: "Role",
      key: "name",
    },
  ];

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Settings route="roles">
        <Row sx={{ mb: 8, justifyContent: "space-between", alignItems: "center" }}>
          <Text sx={{ fontSize: 3, fontWeight: "semi" }}>Roles</Text>
          <Permission>
            <Button loading={creating} onClick={() => addRole({ name: "Custom Role", permissions: DEFAULT_POLICY })}>
              Add Role
            </Button>
          </Permission>
        </Row>
        <Table
          columns={roleColumns}
          data={roles}
          loading={isLoading}
          placeholder={placeholder}
          rowHeight={55}
          onRowClick={(role) => setEditingRole(role)}
        />
      </Settings>

      <RoleModal
        close={() => setEditingRole(undefined)}
        open={Boolean(editingRole)}
        role={editingRole}
        workspaceId={workspace?.id}
      />
    </PermissionProvider>
  );
};

export const RoleModal = ({ workspaceId, role, close, open }) => {
  const { addToast } = useToasts();
  const [editingRole, setRole] = useState<Partial<Role> | undefined>(role);
  const { isLoading: updating, mutateAsync: updateRole } = useUpdateWorkspaceRoleMutation();
  const [policy, setPolicy] = useState<string>(
    role?.permission ? JSON.stringify(role?.permission, null, 2) : JSON.stringify(DEFAULT_POLICY, null, 2),
  );
  const [validPolicy, setValidPolicy] = useState<boolean>(() => {
    try {
      JSON.parse(policy);
      return true;
    } catch (e) {
      return false;
    }
  });

  const checkValidPolicy = (permissions = "{}", shouldSetRole?: boolean) => {
    setPolicy(permissions);
    try {
      const parsed = JSON.parse(permissions);
      if (shouldSetRole) {
        setRole({ ...editingRole, permissions: parsed });
      }
      setValidPolicy(true);
    } catch (e) {
      setValidPolicy(false);
    }
  };

  useEffect(() => {
    setRole(role);
    checkValidPolicy(JSON.stringify(role?.permissions, null, 2));
  }, [role]);

  const handleClose = () => {
    close();
    setRole(undefined);
  };

  const handleChangePolicy = (event) => {
    checkValidPolicy(event.target.value, true);
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!validPolicy || !editingRole?.name}
            loading={updating}
            onClick={async () => {
              await updateRole({
                workspaceId,
                roleId: role.id,
                name: editingRole?.name ?? "",
                permissions: editingRole?.permissions,
              });
              addToast(`Workspace Role ${name} updated!`, {
                appearance: "success",
              });
              analytics.track("Role Updated", {
                workspace_id: workspaceId,
                name: editingRole?.name,
              });
            }}
          >
            Save
          </Button>
        </>
      }
      isOpen={open}
      sx={{ maxWidth: "441px", width: "100%" }}
      title={`Manage Role: ${role?.name}`}
      onClose={handleClose}
    >
      <>
        <Field label="Name">
          <Input
            placeholder="Enter a name..."
            value={editingRole?.name}
            onChange={(name) => {
              setRole({ ...(editingRole || {}), name });
            }}
          />
        </Field>
        <Field label="Policy">
          <TextArea placeholder="Enter a policy..." value={policy} onChange={handleChangePolicy} />
          {validPolicy ? "valid" : "invalid"}
        </Field>
      </>
    </Modal>
  );
};
