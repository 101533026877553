import { useState } from "react";

import { Grid, Text, Image } from "theme-ui";

import { alertTypes } from "src/components/alerts";
import { Alert } from "src/components/alerts/types";
import { useUser } from "src/contexts/user-context";
import { useCreateWorkspaceAlertMutation, useUpdateWorkspaceAlertMutation } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Select } from "src/ui/select";

const isComplete = (alert) => {
  const base = alert?.name && alert?.type;

  const validator = alertTypes?.[alert?.type]?.validator;
  const platform = !validator || (validator && validator(alert));

  return base && platform;
};

export const EditAlert = ({ onClose, alert: _alert }) => {
  const [alert, setAlert] = useState({
    name: _alert?.name,
    type: _alert?.type,
    fatalErrorDefault: _alert?.fatal_error_default,
    rowErrorDefault: _alert?.row_error_default,
    config: _alert?.config,
    slackCredentialId: _alert?.slack_credential_id,
    pagerdutyCredentialId: _alert?.pagerduty_credential_id,
    alertInterval: _alert?.alert_interval,
  });

  const { isLoading: updating, mutateAsync: updateAlert } = useUpdateWorkspaceAlertMutation();

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isComplete(alert)}
            loading={updating}
            onClick={async () => {
              await updateAlert({
                id: _alert?.id,
                input: {
                  name: alert?.name,
                  fatal_error_default: alert?.fatalErrorDefault,
                  row_error_default: alert?.rowErrorDefault,
                  config: alert?.config,
                  slack_credential_id: alert?.slackCredentialId,
                  pagerduty_credential_id: alert?.pagerdutyCredentialId,
                  alert_interval: alert?.alertInterval,
                },
              });
              onClose();
            }}
          >
            Save
          </Button>
        </>
      }
      sx={{ maxWidth: "800px", width: "100%" }}
      title="Edit Alert"
      onClose={onClose}
    >
      <AlertForm alert={alert} edit={true} setAlert={setAlert} />
    </Modal>
  );
};

export const CreateAlert = ({ onClose }) => {
  const { user } = useUser();
  const [alert, setAlert] = useState<Alert>({
    config: {},
  });

  const { isLoading: creating, mutateAsync: createAlert } = useCreateWorkspaceAlertMutation();

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isComplete(alert)}
            loading={creating}
            onClick={async () => {
              await createAlert({
                input: {
                  name: alert?.name,
                  type: alert?.type,
                  fatal_error_default: alert?.fatalErrorDefault,
                  row_error_default: alert?.rowErrorDefault,
                  config: alert?.config,
                  slack_credential_id: alert?.slackCredentialId,
                  pagerduty_credential_id: alert?.pagerdutyCredentialId,
                  alert_interval: alert?.alertInterval != null ? String(alert?.alertInterval) : undefined,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                },
              });
              onClose();
            }}
          >
            Create
          </Button>
        </>
      }
      sx={{ maxWidth: "800px", width: "100%" }}
      title="Create Alert"
      onClose={onClose}
    >
      <AlertForm alert={alert} setAlert={setAlert} />
    </Modal>
  );
};

const AlertForm = ({ alert, setAlert, edit = false }) => {
  const ConfigForm = alertTypes?.[alert?.type]?.form;

  return (
    <Grid gap={6}>
      <Field label="Name">
        <Input
          placeholder="Enter a name..."
          value={alert?.name}
          onChange={(name) => {
            setAlert({ ...alert, name });
          }}
        />
      </Field>
      {!edit && (
        <Field label="Platform">
          <Select
            formatOptionLabel={(object) => (
              <Row sx={{ alignItems: "center" }}>
                <Image src={alertTypes[object.value].icon} sx={{ mr: 2 }} width="18px" />
                <Text>{object.label}</Text>
              </Row>
            )}
            options={Object.entries(alertTypes).map(([k, v]) => ({
              label: v.name,
              value: k,
            }))}
            value={alert?.type}
            onChange={(selected) => {
              if (selected?.value !== alert?.type) {
                setAlert({ ...alert, type: selected?.value, config: {} });
              } else {
                setAlert({ ...alert, type: selected?.value });
              }
            }}
          />
        </Field>
      )}
      {ConfigForm && (
        <ConfigForm
          alert={alert}
          config={alert?.config || {}}
          setAlert={setAlert}
          setConfig={(config) => {
            setAlert({ ...alert, config });
          }}
        />
      )}
      <Field
        help="Enabling the default behavior will apply this alert to all syncs for the specified alert condition."
        label="Default Behaviors"
      >
        <Grid gap={1}>
          <Checkbox
            label="Trigger on fatal error"
            value={alert?.fatalErrorDefault}
            onChange={(fatalErrorDefault) => {
              setAlert({ ...alert, fatalErrorDefault });
            }}
          />
          <Checkbox
            label="Trigger on row error"
            value={alert?.rowErrorDefault}
            onChange={(rowErrorDefault) => {
              setAlert({ ...alert, rowErrorDefault });
            }}
          />
        </Grid>
      </Field>
      <Field
        optional
        help="This defines the minimum interval at which Hightouch can notify you of errors with this alert. For example, if the interval is 60 minutes, any errors occuring on syncs that have already triggered an alert will not send another alert until 60 minutes has elapsed."
        label="Minimum Alert Interval"
      >
        <Input
          min="0"
          placeholder="Enter an alert interval (minutes)..."
          type="number"
          value={alert?.alertInterval}
          onChange={(alertInterval) => {
            setAlert({ ...alert, alertInterval: alertInterval || alertInterval === 0 ? Number(alertInterval) : null });
          }}
        />
      </Field>
    </Grid>
  );
};
