import { useCallback, useMemo, useState, VFC } from "react";

import { capitalize, debounce } from "lodash";
import { Text } from "theme-ui";

import { useUpdateModelColumnMutation, useUpdateModelColumnsMutation } from "src/graphql";
import { ColumnType } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Row, Wrap } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { Heading } from "src/ui/heading";
import { Input, SearchInput } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";
import { Table } from "src/ui/table";
import { Toggle } from "src/ui/toggle";
import { formatDate } from "src/utils/time";

import { ErrorModal } from "../modals/error-modal";
import { Permission } from "../permission";

type Props = {
  source?: any;
  modelId: string;
  columns: any;
};

const SUPPORTED_SUGGESTION_SOURCES = ["postgres", "bigquery", "snowflake", "athena", "redshift", "databricks"];
const SUPPORTED_JSON_ARRAY_SOURCES = ["postgres", "bigquery", "snowflake"];
// TODO: turn this on for more sources once an appropriate casting implementation exists
const SUPPORTED_COLUMN_CAST_SOURCES = ["snowflake"];

export const ColumnSettings: VFC<Readonly<Props>> = ({ modelId, source, columns }) => {
  const [search, setSearch] = useState<string>("");

  const refreshInterval = columns?.find(({ top_k_sync_interval }) => Boolean(top_k_sync_interval))?.top_k_sync_interval;
  const refreshing = Boolean(columns?.find(({ trigger_top_k_sync, top_k_enabled }) => top_k_enabled && trigger_top_k_sync));

  const [currentRefreshInterval, setCurrentRefreshInterval] = useState<number>(refreshInterval);

  const suggestionsDisabled = !SUPPORTED_SUGGESTION_SOURCES.includes(source?.type);

  const jsonArraysEnabled = SUPPORTED_JSON_ARRAY_SOURCES.includes(source?.type);

  const columnCastsEnabled = SUPPORTED_COLUMN_CAST_SOURCES.includes(source?.type);

  const { mutateAsync: updateColumn } = useUpdateModelColumnMutation();
  const { mutateAsync: updateColumns } = useUpdateModelColumnsMutation();

  const updateAlias = useCallback(
    debounce((name, alias) => {
      updateColumn({ id: modelId, name, input: { alias } });
    }, 1500),
    [updateColumn, modelId],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: "Enabled",
        max: "100px",
        cell: ({ disable, name }) => {
          const handleChange = useCallback(
            (checked) => {
              updateColumn({ id: modelId, name, input: { disable: !checked } });
            },
            [name, modelId, updateColumn],
          );

          return <Checkbox value={!disable} onChange={handleChange} />;
        },
      },
      {
        key: "name",
        name: "Name",
      },
      {
        name: "Type",
        min: "150px",
        max: "250px",
        cell: ({ name, type: warehouseType, custom_type: customType, disable }) => {
          const type = customType ?? warehouseType;

          const isValidJsonArrayColumnType = [
            ColumnType.Json,
            ColumnType.JsonArrayStrings,
            ColumnType.JsonArrayNumbers,
          ].includes(type);

          if (columnCastsEnabled && warehouseType === ColumnType.String) {
            return (
              <NewSelect
                disabled={disable}
                options={[
                  { label: "String", value: ColumnType.String },
                  { label: "Number", value: ColumnType.Number },
                  { label: "Timestamp", value: ColumnType.Timestamp },
                ]}
                strategy="absolute"
                value={type}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { custom_type: value } });
                }}
              />
            );
          }

          if (jsonArraysEnabled && isValidJsonArrayColumnType) {
            return (
              <NewSelect
                disabled={disable}
                options={[
                  { label: "JSON Array (Strings)", value: ColumnType.JsonArrayStrings },
                  { label: "JSON Array (Numbers)", value: ColumnType.JsonArrayNumbers },
                ]}
                placeholder={"Please select a type..."}
                strategy="absolute"
                value={type === ColumnType.Json ? undefined : type}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { custom_type: value } });
                }}
              />
            );
          }

          return <Text>{capitalize(type)}</Text>;
        },
      },
      {
        min: "200px",
        name: "Alias",
        cell: ({ alias, name, disable }) => {
          const [value, setValue] = useState<string>(alias);

          return (
            <Input
              disabled={disable}
              placeholder="Add an alias..."
              value={value}
              onChange={(value) => {
                setValue(value);
                updateAlias(name, value);
              }}
            />
          );
        },
      },
      {
        name: "Suggestions",
        cell: ({
          top_k_enabled: enabled,
          name,
          last_top_k_sync_timestamp: timestamp,
          top_k_error: error,
          disable,
          disable_preview,
        }) => {
          return (
            <Row sx={{ alignItems: "center" }}>
              <Toggle
                disabled={suggestionsDisabled || disable || disable_preview}
                label={enabled ? "On" : "Off"}
                sx={{ mr: error || timestamp ? 4 : 0 }}
                value={enabled}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { top_k_enabled: value } });
                }}
              />
              {!disable && (
                <>
                  {error ? (
                    <ErrorModal error={error} />
                  ) : timestamp && enabled ? (
                    <Text sx={{ color: "base.5", fontSize: 0 }}>Last updated {formatDate(timestamp)}</Text>
                  ) : null}
                </>
              )}
            </Row>
          );
        },
      },
      {
        name: "Previewable",
        max: "100px",
        cell: ({ disable_preview, disable, name }) => {
          return (
            <Checkbox
              disabled={disable}
              value={!disable_preview}
              onChange={(checked) => {
                updateColumn({
                  id: modelId,
                  name,
                  input: { disable_preview: !checked, top_k_enabled: checked ? undefined : false },
                });
              }}
            />
          );
        },
      },
    ],
    [suggestionsDisabled, updateColumn, updateAlias],
  );

  const filteredColumns = useMemo(
    () => columns.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())),
    [search, columns],
  );

  const disabled = useCallback(({ disable }: { disable: boolean }) => disable, []);

  return (
    <>
      <Wrap spacing={2} sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Row sx={{ alignItems: "center", flexShrink: 0 }}>
          <Heading sx={{ mr: 2 }}>Columns</Heading>
          <Badge sx={{ mr: 4 }} variant="base">
            {columns?.length}
          </Badge>
          <SearchInput placeholder="Search columns..." value={search} onChange={setSearch} />
        </Row>
        <Row sx={{ alignItems: "center", justifyContent: "flex-end", flexShrink: 0 }}>
          <Text sx={{ mr: 2, color: "base.6", whiteSpace: "nowrap" }}>Refresh interval</Text>
          <NewSelect
            options={REFRESH_OPTIONS}
            placeholder="Select an interval..."
            value={currentRefreshInterval}
            onChange={(value) => {
              if (value) {
                setCurrentRefreshInterval(value);
                updateColumns({
                  id: modelId,
                  input: {
                    top_k_sync_interval: value,
                  },
                });
              }
            }}
          />
          <Permission>
            <Button
              disabled={refreshing}
              sx={{ ml: 2 }}
              variant="secondary"
              onClick={() => {
                updateColumns({
                  id: modelId,
                  input: {
                    trigger_top_k_sync: true,
                  },
                });
              }}
            >
              {refreshing ? "Refreshing..." : "Refresh all"}
            </Button>
          </Permission>
        </Row>
      </Wrap>

      <Table
        columns={tableColumns}
        data={filteredColumns}
        disabled={disabled}
        primaryKey="name"
        rowHeight={50}
        sx={{ mt: 4 }}
      />
    </>
  );
};

const HOURLY = 3600;
const DAILY = 86400;
const WEEKLY = 604800;
const BI_WEEKLY = 1209600;
const MONTHLY = 2419200;

const REFRESH_OPTIONS = [
  {
    label: "Hourly",
    value: HOURLY,
  },
  {
    label: "Daily",
    value: DAILY,
  },
  {
    label: "Weekly",
    value: WEEKLY,
  },
  {
    label: "Bi-weekly",
    value: BI_WEEKLY,
  },
  {
    label: "Monthly",
    value: MONTHLY,
  },
];
