import { useEffect, useMemo, VFC } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Text, ThemeUIStyleObject } from "theme-ui";

import { ConnectionsBoolExp, DestinationsBoolExp, SegmentsBoolExp, SyncsBoolExp } from "src/graphql";
import { SquareBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { XIcon, PlusIcon, ChevronDownIcon } from "src/ui/icons";
import { FilterIcon } from "src/ui/icons/filter";
import { MultiSelect } from "src/ui/new-select";
import { Popout } from "src/ui/popout";
import { Select } from "src/ui/select";

import { LabelSelect } from "../labels/label-select";
import { getPropertyOptions, getValidFilters } from "./config";
import { Filter, FilterType, FilterOperator, FilterDefinitions } from "./types";

type Props = {
  filters: Filter[] | undefined;
  resourceType: "sync" | "destination" | "segment" | "connection";
  onChange: (filters: Filter[]) => void;
  data: any;
  filterDefinitions: FilterDefinitions<SegmentsBoolExp | SyncsBoolExp | DestinationsBoolExp | ConnectionsBoolExp>;
  sx?: ThemeUIStyleObject;
};

/**
 * Returns the default filter from the configuration
 *
 * @param filterDefinitions The configuration for the filter
 * @returns The default filter
 */
const getDefaultFilter = (filterDefinitions: FilterDefinitions<SegmentsBoolExp | SyncsBoolExp>): Filter => {
  const entries = Object.entries(filterDefinitions || {});

  const [defaultType, { operatorOptions }] = entries.find(([, value]) => value.default)!;

  return {
    type: defaultType as FilterType,
    operator: operatorOptions?.[0].value,
    value: [],
  };
};

export const Filters: VFC<Readonly<Props>> = ({ data, filterDefinitions, filters, resourceType, onChange, sx = {} }) => {
  const defaultFilter = getDefaultFilter(filterDefinitions);

  const { control, handleSubmit, watch, reset, setValue } = useForm<{ filters: Filter[] }>({
    defaultValues: { filters: filters?.length ? filters : [defaultFilter] },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "filters",
  });

  const values = watch();

  const propertyOptions = useMemo(() => getPropertyOptions(filterDefinitions), [filterDefinitions]);

  const submit = handleSubmit(({ filters }) => {
    const validFilters = getValidFilters(filters);
    onChange(validFilters);
  });

  useEffect(() => {
    // reset filters when filters change
    reset({ filters: filters?.length ? filters : [defaultFilter] });
  }, [filters, reset]);

  return (
    <Popout
      content={() => (
        <Column>
          <Column sx={{ maxHeight: "400px", overflowY: "auto" }}>
            {fields.map((field, index) => {
              const filterType = values.filters[index]!.type;
              const definition = filterDefinitions[filterType]!;

              return (
                <Row key={field.id} sx={{ alignItems: "center", borderBottom: "small" }}>
                  <Text sx={{ p: 4 }}>Where</Text>
                  <Controller
                    control={control}
                    name={`filters.${index}.type` as const}
                    render={({ field }) => (
                      <Select
                        options={propertyOptions}
                        placeholder={"Property..."}
                        sx={{ p: 4, borderRight: "small", borderLeft: "small" }}
                        value={field.value}
                        onChange={(option) => {
                          field.onChange(option?.value || undefined);
                          setValue(
                            `filters.${index}.operator`,
                            option?.value === FilterType.Label ? FilterOperator.IncludesKeys : FilterOperator.Includes,
                          );
                          setValue(`filters.${index}.value`, undefined);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`filters.${index}.operator` as const}
                    render={({ field }) => (
                      <Select
                        options={definition.operatorOptions}
                        placeholder="Operator..."
                        sx={{ p: 4 }}
                        value={field.value}
                        onChange={(option) => field.onChange(option?.value || undefined)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`filters.${index}.value` as const}
                    render={({ field }) => {
                      if (filterType === FilterType.Label) {
                        return (
                          <Row sx={{ p: 4, borderLeft: "small", flex: 1 }}>
                            <LabelSelect
                              resourceType={resourceType}
                              value={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          </Row>
                        );
                      }
                      return (
                        <Row sx={{ p: 4, borderLeft: "small", flex: 1 }}>
                          <MultiSelect
                            label={definition.value}
                            options={definition.valueOptions?.(data) ?? []}
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                            {...(definition.props || {})}
                          />
                        </Row>
                      );
                    }}
                  />
                  {fields.length > 1 && (
                    <Button sx={{ mr: 4 }} variant="plain" onClick={() => remove(index)}>
                      <XIcon color="base.4" size={16} />
                    </Button>
                  )}
                </Row>
              );
            })}
          </Column>

          <Row sx={{ p: 3, alignItems: "center", justifyContent: "space-between" }}>
            <Button
              iconBefore={<PlusIcon size={14} />}
              variant="secondary"
              onClick={() => {
                append(defaultFilter, { shouldFocus: false });
              }}
            >
              Add filter
            </Button>
          </Row>
        </Column>
      )}
      contentSx={{ width: "700px", borderRadius: 2, bg: "white" }}
      sx={sx}
      onClose={submit}
    >
      <Button
        propagate
        iconAfter={<ChevronDownIcon color="base.7" size={16} />}
        iconBefore={<FilterIcon color="base.7" size={14} />}
        variant="secondary"
      >
        Filter
        {Array.isArray(filters) && filters.length > 0 && <SquareBadge sx={{ ml: 2 }}>{filters.length}</SquareBadge>}
      </Button>
    </Popout>
  );
};
